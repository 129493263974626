export interface ICoinPageCurrency {
  name: string
  useTicker?: boolean
  altName?: string
  customFields?: {
    calculatorHeading?: string
    lendHeading?: string
    lendInfoName?: string
    lendStepHeading?: string
    communityHeading?: string
    whatIsCoinName?: string
  }
  lendInfoName?: string
  lendInfoHeading?: string
  calculatorHeading?: string
  isToken?: boolean
  pageName?: string
  code: string
  network: string
  coinPageUrl: string
  logoUrl: string
  borrowTitle?: boolean
  textV2?: boolean
  missWhatIsCoin?: boolean
  background?: { data: { id: number; attributes: { url: string } } }
}

// key: `${code}-${network}`
export const coinPagesList: {
  [key: string]: ICoinPageCurrency
} = {
  'usdt-eth': {
    name: 'Tether',
    altName: 'USDT (ETH)',
    customFields: {
      calculatorHeading: 'USDT (ETH)',
      lendHeading: 'Tether (ETH)',
      lendInfoName: 'Tether (ETH)',
      lendStepHeading: 'Tether (ETH)',
      whatIsCoinName: 'Tether (ETH)',
      communityHeading: 'Tether (ETH)',
    },
    code: 'usdt',
    network: 'eth',
    coinPageUrl: 'usdt',
    logoUrl: 'https://changenow.io/images/sprite/currencies/usdterc20.svg',
  },
  'usdc-eth': {
    name: 'USD Coin',
    altName: 'USDC (ETH)',
    customFields: {
      calculatorHeading: 'USDC (ETH)',
      lendHeading: 'USDC (ETH)',
      lendInfoName: 'USDC (ETH)',
      lendStepHeading: 'USDC (ETH)',
      whatIsCoinName: 'USDC (ETH)',
      communityHeading: 'USDC (ETH)',
    },
    code: 'usdc',
    network: 'eth',
    coinPageUrl: 'usdc',
    logoUrl: 'https://changenow.io/images/sprite/currencies/usdc.svg',
  },
  'usdt-trx': {
    name: 'Tether',
    altName: 'USDT (TRX)',
    customFields: {
      calculatorHeading: 'USDT (TRX)',
      lendHeading: 'Tether (TRX)',
      lendInfoName: 'Tether (TRX)',
      lendStepHeading: 'Tether (TRX)',
      whatIsCoinName: 'Tether (TRX)',
      communityHeading: 'Tether (TRX)',
    },
    code: 'usdt',
    pageName: 'usdttrc20',
    network: 'trx',
    coinPageUrl: 'usdttrc20',
    logoUrl: 'https://changenow.io/images/sprite/currencies/usdttrc20.svg',
  },
  'btc-btc': {
    name: 'Bitcoin',
    code: 'btc',
    network: 'btc',
    coinPageUrl: 'btc',
    logoUrl: 'https://changenow.io/images/sprite/currencies/btc.svg',
  },
  'eth-eth': {
    name: 'Ethereum',
    code: 'eth',
    network: 'eth',
    coinPageUrl: 'eth',
    logoUrl: 'https://changenow.io/images/sprite/currencies/eth.svg',
  },
  'bch-bch': {
    name: 'Bitcoin Cash',
    code: 'bch',
    network: 'bch',
    coinPageUrl: 'bch',
    logoUrl: 'https://changenow.io/images/sprite/currencies/bch.svg',
  },
  'firo-firo': {
    name: 'Firo',
    code: 'firo',
    network: 'firo',
    coinPageUrl: 'firo',
    logoUrl: 'https://changenow.io/images/sprite/currencies/firo.svg',
  },
  'xno-nano': {
    name: 'XNO',
    code: 'xno',
    network: 'nano',
    coinPageUrl: 'xno',
    logoUrl: 'https://changenow.io/images/sprite/currencies/nano.svg',
  },
  'doge-doge': {
    name: 'Doge',
    code: 'doge',
    network: 'doge',
    coinPageUrl: 'doge',
    logoUrl: 'https://changenow.io/images/sprite/currencies/doge.svg',
  },
  'xrp-xrp': {
    name: 'Ripple',
    code: 'xrp',
    network: 'xrp',
    coinPageUrl: 'xrp',
    logoUrl: 'https://changenow.io/images/sprite/currencies/xrp.svg',
  },
  'xaut-eth': {
    name: 'XAUt',
    code: 'xaut',
    network: 'eth',
    coinPageUrl: 'xaut',
    logoUrl: 'https://changenow.io/images/sprite/currencies/xaut.svg',
  },
  'comp-eth': {
    name: 'Compound',
    code: 'comp',
    network: 'eth',
    coinPageUrl: 'comp',
    logoUrl: 'https://changenow.io/images/sprite/currencies/comp.svg',
  },
  'dgb-dgb': {
    name: 'Digibyte',
    code: 'dgb',
    network: 'dgb',
    coinPageUrl: 'dgb',
    logoUrl: 'https://changenow.io/images/sprite/currencies/dgb.svg',
  },
  'xmr-xmr': {
    name: 'Monero',
    code: 'xmr',
    network: 'xmr',
    coinPageUrl: 'xmr',
    logoUrl: 'https://changenow.io/images/sprite/currencies/xmr.svg',
  },
  'rvn-rvn': {
    name: 'Ravencoin',
    code: 'rvn',
    network: 'rvn',
    coinPageUrl: 'rvn',
    logoUrl: 'https://changenow.io/images/sprite/currencies/rvn.svg',
  },
  'shib-eth': {
    name: 'SHIBA INU',
    code: 'shib',
    network: 'eth',
    coinPageUrl: 'shib',
    logoUrl: 'https://changenow.io/images/sprite/currencies/shib.svg',
  },
  'zil-zil': {
    name: 'Zilliqa',
    code: 'zil',
    network: 'zil',
    coinPageUrl: 'zil',
    logoUrl: 'https://changenow.io/images/sprite/currencies/zil.svg',
  },
  'ada-ada': {
    name: 'Cardano',
    altName: 'Cardano coin',
    code: 'ada',
    network: 'ada',
    coinPageUrl: 'ada',
    logoUrl: 'https://changenow.io/images/sprite/currencies/ada.svg',
  },
  'lrc-eth': {
    name: 'Loopring',
    code: 'lrc',
    isToken: true,
    network: 'eth',
    coinPageUrl: 'lrc',
    logoUrl: 'https://changenow.io/images/sprite/currencies/lrc.svg',
  },
  'uni-eth': {
    name: 'Uniswap',
    code: 'uni',
    isToken: true,
    network: 'eth',
    coinPageUrl: 'uni',
    logoUrl: 'https://changenow.io/images/sprite/currencies/uni.svg',
  },
  'link-eth': {
    name: 'Chainlink Token',
    altName: 'ChainLink',
    lendInfoName: 'Chainlink crypto',
    code: 'link',
    network: 'eth',
    coinPageUrl: 'link',
    logoUrl: 'https://changenow.io/images/sprite/currencies/link.svg',
  },
  'vet-vet': {
    name: 'VeChain',
    code: 'vet',
    network: 'vet',
    coinPageUrl: 'vet',
    logoUrl: 'https://changenow.io/images/sprite/currencies/vet.svg',
  },
  'sushi-eth': {
    altName: 'Sushi Token',
    name: 'Sushi Token',
    lendInfoName: 'Sushi crypto',
    code: 'sushi',
    network: 'eth',
    coinPageUrl: 'sushi',
    logoUrl: 'https://changenow.io/images/sprite/currencies/sushi.svg',
  },
  'ilv-eth': {
    name: 'Illuvium',
    altName: 'ILV Token',
    code: 'ilv',
    network: 'eth',
    coinPageUrl: 'ilv',
    logoUrl: 'https://changenow.io/images/sprite/currencies/ilv.svg',
  },
  'zrx-eth': {
    name: '0x',
    useTicker: true,
    lendInfoName: 'ZRX',
    customFields: {
      calculatorHeading: '0x (ZRX)',
      lendHeading: 'ZRX Coin',
      lendInfoName: 'ZRX',
      lendStepHeading: 'ZRX coin',
      communityHeading: '0x coin',
    },
    altName: 'ZRX',
    code: 'zrx',
    network: 'eth',
    coinPageUrl: 'zrx',
    logoUrl: 'https://changenow.io/images/sprite/currencies/zrx.svg',
    isToken: true,
  },
  'sol-sol': {
    name: 'Solana',
    customFields: {
      calculatorHeading: 'Solana (SOL)',
      lendHeading: 'Solana Coin',
      lendInfoName: 'SOL',
      lendStepHeading: 'Solana coin',
    },
    altName: 'SOL',
    code: 'sol',
    network: 'sol',
    coinPageUrl: 'sol',
    logoUrl: 'https://changenow.io/images/sprite/currencies/sol.svg',
  },
  'dash-dash': {
    name: 'Dash',
    customFields: {
      calculatorHeading: 'Dash (DASH)',
      lendHeading: 'Dash Coin',
      lendInfoName: 'DASH',
      lendStepHeading: 'Dash coin',
    },
    altName: 'DASH',
    code: 'dash',
    network: 'dash',
    coinPageUrl: 'dash',
    logoUrl: 'https://changenow.io/images/sprite/currencies/dash.svg',
  },
  'mana-eth': {
    name: 'Decentraland',
    customFields: {
      calculatorHeading: 'Decentraland (MANA)',
      lendHeading: 'Decentraland Coin',
      lendInfoName: 'MANA',
      lendStepHeading: 'Decentraland Coin',
    },
    altName: 'MANA',
    code: 'mana',
    network: 'eth',
    coinPageUrl: 'mana',
    logoUrl: 'https://changenow.io/images/sprite/currencies/mana.svg',
  },
  'trx-trx': {
    name: 'Tron',
    customFields: {
      calculatorHeading: 'Tron (TRX)',
      lendHeading: 'Tron Crypto',
      lendInfoName: 'TRX',
      lendStepHeading: 'Tron Coin',
    },
    altName: 'TRX',
    code: 'trx',
    network: 'trx',
    coinPageUrl: 'trx',
    logoUrl: 'https://changenow.io/images/sprite/currencies/mana.svg',
  },
  'ape-eth': {
    name: 'ApeCoin',
    customFields: {
      calculatorHeading: 'ApeCoin (APE)',
      lendHeading: 'ApeCoin Crypto',
      lendInfoName: 'APE',
      lendStepHeading: 'ApeCoin',
    },
    altName: 'APE',
    code: 'ape',
    network: 'eth',
    coinPageUrl: 'ape',
    logoUrl: 'https://changenow.io/images/sprite/currencies/ape.svg',
  },
  'hot-eth': {
    name: 'Holo',
    customFields: {
      calculatorHeading: 'Holo (HOT)',
      lendHeading: 'Holo Crypto',
      lendInfoName: 'HOT',
      lendStepHeading: 'Holo coin',
    },
    altName: 'HOT',
    code: 'hot',
    network: 'eth',
    coinPageUrl: 'hot',
    logoUrl: 'https://changenow.io/images/sprite/currencies/hot.svg',
  },
  'aave-eth': {
    name: 'Aave',
    customFields: {
      calculatorHeading: 'Aave',
      lendHeading: 'Aave Crypto',
      lendInfoName: 'Aave',
      lendStepHeading: 'Aave coin',
    },
    altName: 'AAVE',
    code: 'aave',
    network: 'eth',
    coinPageUrl: 'aave',
    logoUrl: 'https://changenow.io/images/sprite/currencies/aave.svg',
  },
  'ftm-eth': {
    name: 'Fantom',
    customFields: {
      calculatorHeading: 'Fantom Token',
      lendHeading: 'Fantom Token',
      lendInfoName: 'FTM',
      lendStepHeading: 'Fantom Token',
      communityHeading: 'Fantom Token',
    },
    altName: 'FTM',
    code: 'ftm',
    network: 'eth',
    coinPageUrl: 'ftm',
    logoUrl: 'https://changenow.io/images/sprite/currencies/ftm.svg',
  },
  'bnb-bsc': {
    name: 'Binance',
    customFields: {
      calculatorHeading: 'Binance Coin',
      lendHeading: 'Binance Coin',
      lendInfoName: 'BNB',
      lendStepHeading: 'Binance Coin',
    },
    altName: 'BNB',
    code: 'bnb',
    network: 'bsc',
    coinPageUrl: 'bnb',
    logoUrl: 'https://changenow.io/images/sprite/currencies/bnb.svg',
  },
  'dot-dot': {
    name: 'Polkadot',
    customFields: {
      calculatorHeading: 'Polkadot',
      lendHeading: 'Polkadot',
      lendInfoName: 'Polkadot',
      lendStepHeading: 'Polkadot coin',
    },
    altName: 'DOT',
    code: 'dot',
    network: 'dot',
    coinPageUrl: 'dot',
    logoUrl: 'https://changenow.io/images/sprite/currencies/dot.svg',
  },
  'ltc-ltc': {
    name: 'Litecoin',
    customFields: {
      calculatorHeading: 'Litecoin',
      lendHeading: 'Litecoin',
      lendInfoName: 'Litecoin',
      lendStepHeading: 'Litecoin coin',
    },
    altName: 'LTC',
    code: 'ltc',
    network: 'ltc',
    coinPageUrl: 'ltc',
    logoUrl: 'https://changenow.io/images/sprite/currencies/ltc.svg',
  },
  'mkr-eth': {
    name: 'Maker',
    pageName: 'mkr',
    customFields: {
      calculatorHeading: 'Maker',
      lendHeading: 'Maker',
      lendInfoName: 'Maker',
      lendStepHeading: 'Maker coin',
    },
    altName: 'MKR',
    code: 'mkr',
    network: 'eth',
    coinPageUrl: 'mkr',
    logoUrl: 'https://changenow.io/images/sprite/currencies/mkr.svg',
  },
  'matic-matic': {
    name: 'Polygon',
    customFields: {
      calculatorHeading: 'Polygon',
      lendHeading: 'Polygon',
      lendInfoName: 'Polygon',
      lendStepHeading: 'Polygon coin',
    },
    altName: 'MATIC',
    code: 'matic',
    network: 'matic',
    coinPageUrl: 'matic',
    logoUrl: 'https://changenow.io/images/sprite/currencies/matic.svg',
  },
  'zec-zec': {
    name: 'Zcash',
    customFields: {
      calculatorHeading: 'Zcash',
      lendHeading: 'Zcash',
      lendInfoName: 'Zcash',
      lendStepHeading: 'Zcash coin',
    },
    altName: 'ZEC',
    code: 'zec',
    network: 'zec',
    coinPageUrl: 'zec',
    logoUrl: 'https://changenow.io/images/sprite/currencies/zec.svg',
  },
  'algo-algo': {
    name: 'Algorand',
    customFields: {
      calculatorHeading: 'Algorand',
      lendHeading: 'Algorand',
      lendInfoName: 'Algorand',
      lendStepHeading: 'Algorand coin',
    },
    altName: 'ALGO',
    code: 'algo',
    network: 'algo',
    coinPageUrl: 'algo',
    logoUrl: 'https://changenow.io/images/sprite/currencies/algo.svg',
  },
  'floki-eth': {
    name: 'Floki',
    altName: 'FLOKI',
    code: 'floki',
    network: 'eth',
    coinPageUrl: 'floki',
    logoUrl: 'https://changenow.io/images/sprite/currencies/floki.svg',
  },
  'floki-bsc': {
    name: 'Floki',
    altName: 'FLOKI',
    code: 'floki',
    network: 'bsc',
    coinPageUrl: 'flokibsc',
    logoUrl: 'https://changenow.io/images/sprite/currencies/floki.svg',
  },
  'kas-kas': {
    name: 'Kaspa',
    altName: 'Kaspa',
    code: 'kas',
    network: 'kas',
    coinPageUrl: 'kas',
    logoUrl: 'https://content-api.changenow.io/uploads/kaspa_3a12c134af.svg',
  },
  'jasmy-eth': {
    name: 'JasmyCoin',
    altName: 'JASMY',
    code: 'jasmy',
    network: 'eth',
    coinPageUrl: 'jasmy',
    logoUrl: 'https://changenow.io/images/sprite/currencies/jasmy.svg',
  },
  'ont-ont': {
    name: 'Ontology',
    altName: 'ONT',
    code: 'ont',
    network: 'bsc',
    coinPageUrl: 'ont',
    logoUrl: 'https://changenow.io/images/sprite/currencies/ont.svg',
  },
  'xdc-xdc': {
    name: 'XDC',
    altName: 'XDC',
    code: 'xdc',
    network: 'xdc',
    coinPageUrl: 'xdc',
    logoUrl: 'https://changenow.io/images/sprite/currencies/xdc.svg',
  },
  'volt-eth': {
    name: 'Volt Inu',
    altName: 'VOLT',
    code: 'volt',
    network: 'eth',
    coinPageUrl: 'volt',
    logoUrl: 'https://changenow.io/images/sprite/currencies/volt.svg',
  },
  'volt-bsc': {
    name: 'Volt Inu',
    altName: 'VOLT',
    code: 'volt',
    network: 'bsc',
    coinPageUrl: 'volt',
    logoUrl: 'https://changenow.io/images/sprite/currencies/volt.svg',
  },
  'bone-eth': {
    name: 'Bone',
    altName: 'BONE',
    code: 'bone',
    network: 'eth',
    coinPageUrl: 'bone',
    logoUrl: 'https://content-api.changenow.io/uploads/bone_965d68d732.svg',
  },
  'kishu-eth': {
    name: 'Kishu Inu',
    altName: 'KISHU',
    code: 'kishu',
    network: 'eth',
    coinPageUrl: 'kishu',
    logoUrl: 'https://content-api.changenow.io/uploads/kishu_78a838095e.svg',
  },
  'brise-bsc': {
    name: 'BRISE',
    altName: 'BRISE',
    code: 'brise',
    network: 'bsc',
    coinPageUrl: 'brise',
    logoUrl: 'https://content-api.changenow.io/uploads/bitrise_15e3d532e4.svg',
  },
  'babydoge-bsc': {
    name: 'Baby Doge Coin',
    altName: 'BabyDoge',
    code: 'babydoge',
    network: 'bsc',
    coinPageUrl: 'babydoge',
    logoUrl: 'https://changenow.io/images/sprite/currencies/babydoge.svg',
  },
  'quack-bsc': {
    name: 'Rich Quack',
    altName: 'QUACK',
    code: 'quack',
    network: 'bsc',
    coinPageUrl: 'quack',
    logoUrl: 'https://content-api.changenow.io/uploads/quack_0281432d26.svg',
  },
  'raca-eth': {
    name: 'Raca',
    altName: 'RACA',
    code: 'raca',
    network: 'eth',
    coinPageUrl: 'raca',
    borrowTitle: true,
    textV2: true,
    missWhatIsCoin: true,
    logoUrl: 'https://content-api.changenow.io/uploads/racaerc20_f54a214893.svg',
  },
  'flux-eth': {
    name: 'Flux',
    altName: 'FLUX',
    code: 'flux',
    network: 'eth',
    coinPageUrl: 'flux',
    borrowTitle: true,
    textV2: true,
    missWhatIsCoin: true,
    logoUrl: 'https://changenow.io/images/sprite/currencies/flux.svg',
  },
  'flux-bsc': {
    name: 'Flux (BSC)',
    altName: 'FLUX',
    code: 'flux',
    network: 'bsc',
    coinPageUrl: 'flux',
    borrowTitle: true,
    textV2: true,
    missWhatIsCoin: true,
    logoUrl: 'https://changenow.io/images/sprite/currencies/flux.svg',
  },
  'vra-eth': {
    name: 'Verasity',
    altName: 'VRA',
    code: 'vra',
    network: 'eth',
    coinPageUrl: 'vra',
    borrowTitle: true,
    textV2: true,
    missWhatIsCoin: true,
    logoUrl: 'https://changenow.io/images/sprite/currencies/vra.svg',
  },
  'reef-eth': {
    name: 'Reef',
    altName: 'REEF',
    code: 'reef',
    network: 'eth',
    coinPageUrl: 'reef',
    borrowTitle: true,
    textV2: true,
    missWhatIsCoin: true,
    logoUrl: 'https://content-api.changenow.io/uploads/reef_d01cb313c2.svg',
  },
  'reef-bsc': {
    name: 'Reef',
    altName: 'REEF',
    code: 'reef',
    network: 'bsc',
    coinPageUrl: 'reef',
    borrowTitle: true,
    textV2: true,
    missWhatIsCoin: true,
    logoUrl: 'https://content-api.changenow.io/uploads/reef_d01cb313c2.svg',
  },
  'pit-bsc': {
    name: 'Pitbull',
    altName: 'PIT',
    code: 'pit',
    network: 'bsc',
    coinPageUrl: 'pit',
    borrowTitle: true,
    textV2: true,
    missWhatIsCoin: true,
    logoUrl: 'https://content-api.changenow.io/uploads/pit_475b5d9f47.svg',
  },
  'atom-atom': {
    name: 'Cosmos',
    altName: 'ATOM',
    code: 'atom',
    network: 'atom',
    coinPageUrl: 'atom',
    borrowTitle: true,
    textV2: true,
    missWhatIsCoin: true,
    logoUrl: 'https://content-api.changenow.io/uploads/atom_4177c38aa8.svg',
  },
  'hbar-hbar': {
    name: 'Hedera',
    altName: 'HBAR',
    code: 'hbar',
    network: 'hbar',
    coinPageUrl: 'hbar',
    borrowTitle: true,
    textV2: true,
    missWhatIsCoin: true,
    logoUrl: 'https://content-api.changenow.io/uploads/hbar_7a8aadc6c5.svg',
  },
  'okb-eth': {
    name: 'OKB',
    altName: 'OKB',
    code: 'okb',
    network: 'eth',
    coinPageUrl: 'okb',
    borrowTitle: true,
    textV2: true,
    missWhatIsCoin: true,
    logoUrl: 'https://content-api.changenow.io/uploads/okb_958a4b81be.svg',
  },
  'imx-eth': {
    name: 'Immutable',
    altName: 'IMX',
    code: 'imx',
    network: 'eth',
    coinPageUrl: 'imx',
    borrowTitle: true,
    textV2: true,
    missWhatIsCoin: true,
    logoUrl: 'https://content-api.changenow.io/uploads/imx_ef1a66c21c.svg',
  },
  'amp-eth': {
    name: 'Amp',
    altName: 'AMP',
    code: 'amp',
    network: 'eth',
    coinPageUrl: 'amp',
    borrowTitle: true,
    textV2: true,
    missWhatIsCoin: true,
    logoUrl: 'https://content-api.changenow.io/uploads/amp_bfadb9aebc.svg',
  },
  'flr-flr': {
    name: 'Flare',
    altName: 'FLR',
    code: 'flr',
    network: 'flr',
    coinPageUrl: 'flr',
    borrowTitle: true,
    textV2: true,
    missWhatIsCoin: true,
    logoUrl: 'https://content-api.changenow.io/uploads/flr_1f417fd49f.svg',
  },
  'qnt-eth': {
    name: 'Quant',
    altName: 'QNT',
    code: 'qnt',
    network: 'eth',
    coinPageUrl: 'qnt',
    borrowTitle: true,
    textV2: true,
    missWhatIsCoin: true,
    logoUrl: 'https://content-api.changenow.io/uploads/qnt_cde332236b.svg',
  },
  'id-eth': {
    name: 'Space ID',
    altName: 'ID',
    code: 'id',
    network: 'eth',
    coinPageUrl: 'id',
    borrowTitle: true,
    textV2: true,
    missWhatIsCoin: true,
    logoUrl: 'https://content-api.changenow.io/uploads/id_843d06931e.svg',
  },
  'id-bsc': {
    name: 'Space ID',
    altName: 'ID',
    code: 'id',
    network: 'bsc',
    coinPageUrl: 'id',
    borrowTitle: true,
    textV2: true,
    missWhatIsCoin: true,
    logoUrl: 'https://content-api.changenow.io/uploads/idbsc_c741c983d7.svg',
  },
  'inj-inj': {
    name: 'Injective',
    altName: 'INJ',
    code: 'inj',
    network: 'inj',
    coinPageUrl: 'inj',
    borrowTitle: true,
    textV2: true,
    missWhatIsCoin: true,
    logoUrl: 'https://content-api.changenow.io/uploads/injmainnet_c155cd4e32.svg',
  },
  'inj-eth': {
    name: 'Injective',
    altName: 'INJ',
    code: 'inj',
    network: 'eth',
    coinPageUrl: 'inj',
    borrowTitle: true,
    textV2: true,
    missWhatIsCoin: true,
    logoUrl: 'https://content-api.changenow.io/uploads/injerc20_6b9bbcb8c6.svg',
  },
  'inj-bsc': {
    name: 'Injective',
    altName: 'INJ',
    code: 'inj',
    network: 'bsc',
    coinPageUrl: 'inj',
    borrowTitle: true,
    textV2: true,
    missWhatIsCoin: true,
    logoUrl: 'https://content-api.changenow.io/uploads/inj_34bc873730.svg',
  },
  'rune-rune': {
    name: 'THORChain',
    code: 'rune',
    network: 'rune',
    coinPageUrl: 'rune',
    logoUrl: 'https://content-api.changenow.io/uploads/rune_4c80e5a764.svg',
  },
}
